import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'

// axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'http://csh-back-env.us-east-1.elasticbeanstalk.com'

library.add(fas)

require('@/assets/main.scss')
require('moment/locale/es-mx')
moment.locale('es-mx')

createApp(App)
    .use(store)
    .use(router, axios)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')


