<template>
    <div class="container my-5">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Leads desde Formulario Web</h1>

                <router-link to="/dashboard/leads/add">
                    <span class="icon">
                        <font-awesome-icon icon="plus-circle" />
                    </span>
                    Agregar Lead
                </router-link>

                <hr>

                <form @submit.prevent="getLeads">
                    <div class="field has-addons">
                        <div class="control">
                            <input type="text" class="input" v-model="query">
                        </div>
                        <div class="control">
                            <button class="button is-success">
                                <span class="icon">
                                    <font-awesome-icon icon="search" />
                                </span>
                                &nbsp;&nbsp;&nbsp;Buscar
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="column is-12">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>FECHA</th>
                            <th>NOMBRE&#40;S&#41;</th>
                            <th>APELLIDOS</th>
                            <th>CAMPUS</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="lead in leads"
                            v-bind:key="lead.id_aspirante">
                                <td>{{ moment(lead.fecha_mensaje).format('DD/MM/YYYY') }}</td>
                                <td>{{ lead.nombre }}</td>
                                <td>{{ lead.apellidos }}</td>
                                <td>{{ lead.campus }}</td>
                                <td>
                                    <router-link :to="{ name: 'Lead', params: { id_aspirante: lead.id_aspirante }}">
                                        <span class="icon">
                                            <font-awesome-icon icon="folder-open" />
                                        </span>
                                    </router-link>
                                </td>
                        </tr>
                    </tbody>
                </table>

                <div class="buttons">
                    <button class="button is-light" @click="goToPreviousPage()" v-if="showPreviousButton">Anterior</button>
                    <button class="button is-light" @click="goToNextPage()" v-if="showNextButton">Siguiente</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import axios from 'axios'
    import moment from 'moment'

    export default defineComponent({
        name: 'WebForm',
        created: function () {
            this.moment = moment
        },
        data() {
            return {
                leads: [],
                showNextButton: false,
                showPreviousButton: false,
                currentPage: 1,
                query: ''
            }
        },
        mounted() {
            document.title = "Leads Formulario Web | CSH",
            this.getLeads()
        },
        methods: {
            goToNextPage() {
                this.currentPage += 1
                this.getLeads()
            },
            goToPreviousPage() {
                this.currentPage -= 1
                this.getLeads()
            },
            async getLeads() {
                this.$store.commit('setIsLoading', true)

                this.showNextButton = false
                this.showPreviousButton = false

                await axios
                    .get(`/api/v1/leads/?page=${this.currentPage}&search=${this.query}`)
                    .then(response => {
                        console.log(response.data.results)
                        this.leads = response.data.results

                        if (response.data.next) {
                            this.showNextButton = true
                        }

                        if (response.data.previous) {
                            this.showPreviousButton = true
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
            submitForm() {},
        },
        components: {},
    });
</script>
