<template>
    <div class="container section">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Centro de Soporte Humanitas</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Elige tu campus</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="campus">
                                    <option value="Campus Cancún">CANC&Uacute;N</option>
                                    <option value="Campus Colonia Del Valle CDMX">COLONIA DEL VALLE CDMX</option>
                                    <option value="Campus Cuernavaca">CUERNAVACA</option>
                                    <option value="Campus Guadalajara">GUADALAJARA</option>
                                    <option value="Campus Híbrido / Virtual">H&Iacute;BRIDO / VIRTUAL</option>
                                    <option value="...">...</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>Colaborador</label>
                        <div class="control">
                            <input type="text" placeholder="Tu nombre completo" class="input" v-model="colaborador">
                        </div>
                    </div>

                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="email" placeholder="Email institucional @humanitas.edu.mx" class="input" v-model="email">
                        </div>
                    </div>

                    <div class="field">
                        <label>Selecciona tu tipo de solicitud</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="solicitud">
                                    <option value="adm">ADMINISTRACI&Oacute;N</option>
                                    <option value="aca">ACAD&Eacute;MICO</option>
                                    <option value="fin">FINANZAS</option>
                                    <option value="doc">DOCENTES</option>
                                    <option value="alu">ALUMNOS</option>
                                    <option value="asp">ASPIRANTES</option>
                                    <option value="tra">TRASLADOS</option>
                                    <option value="tmk">TEAMBOOK</option>
                                    <option value="goo">CUENTAS DE GOOGLE</option>
                                    <option value="cgo">CLIENGO</option>
                                    <option value="cnv">CANVAS</option>
                                    <option value="paw">P&Aacute;GINA WEB</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>Describe tu solicitud</label>
                        <div class="control">
                            <textarea class="textarea" v-model="descripcion" placeholder="Escribe los detalles de tu solicitud" rows="10"></textarea>
                        </div>
                    </div>
                                    

                    <div class="field">
                        <label>Sube una captura de pantalla, o en caso de ser reset de dispositivo de docente los archivos de la solicitud.</label>
                        <div class="control">
                            <button class="button is-warning">
                                <span class="icon">
                                    <font-awesome-icon icon="file-arrow-up" />
                                </span>
                                &nbsp;&nbsp;&nbsp;Agregar archivo
                            </button>
                        </div>
                    </div>

                    <div class="column is-12">
                        <h1 class="title">Traslados</h1>
                    </div>

                    <article class="message is-primary">
                        <div class="message-header is-size-5 has-text-warning">
                            <p>Periodo de traslados para el ciclo 2022/4, iniciara el lunes 21 de marzo y finaliza el sábado 30 de abril.</p>
                        </div>
                        <div class="message-body content has-text-black">
                            <ol type="1">
                                <li>Solicitar al alumn@ carta o correo, justificando el cambio de campus.</li>
                                <li>Verificar que tenga sus calificaciones registradas completamente, si se encuentra activo y cursando materias o si la situación es diferente a activo, verificar que no tenga pendientes de registro de calificación de ciclos pasados.</li>
                                <li>No presentar adeudos financieros mensualidades pendientes, vencidas o trámites solicitados, sin registro de pago.</li>
                                <li>Hacer mención al alumno que deberá ACEPTAR EL PLAN DE PAGOS ACTUAL DEL CAMPUS DESTINO (no se respeta el costo original, descuentos, becas o alguna promoción, al aplicar este proceso).</li>
                                <li>Una vez verificada esta información, solicitar por medio del formulario del  Centro de Soporte Humanitas, el traslado. (No se realizarán traslados si el campus destino es quién lo solicita o en caso  y se procederá a cancelar el ticket).</li>
                                <li>El Centro de Soporte Humanitas, validará con el CAMPUS DESTINO, si hay grupos disponibles para la asignación, informando al alumn@ en un tiempo no superior a 24hrs.(Después de levantado el ticket). los horarios para que elija alguno de ellos, adicional se indicará y confirmará el nuevo plan de pagos que tendrá al trasladarse.</li>
                                <li>Una vez que el alumno confirma horarios al Centro de Soporte Humanitas, se procede a aplicar el traslado, informando a las coordinaciones de RRPP, Académicas y Administrativas de los  campus destino y origen para su seguimiento y conocimiento.</li>
                                <li>Una vez concluido el traslado el Centro de Soporte Humanitas informa al alumn@ enviando su contraseña de acceso a sistema y de ser requerido sus acceso a correo institucional, para que valide su asignación y seguimiento a su grupo asignado.</li>
                            </ol>
                        </div>
                    </article>

                    <div class="field">
                        <label>Elige campus origen</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="campus_origen">
                                    <option value="Campus Cancún">CANC&Uacute;N</option>
                                    <option value="Campus Colonia Del Valle CDMX">COLONIA DEL VALLE CDMX</option>
                                    <option value="Campus Cuernavaca">CUERNAVACA</option>
                                    <option value="Campus Guadalajara">GUADALAJARA</option>
                                    <option value="Campus Híbrido / Virtual">H&Iacute;BRIDO / VIRTUAL</option>
                                    <option value="...">...</option>
                                </select>
                            </div>
                        </div>
                    </div>

                     <div class="field">
                        <label>Elige campus destino</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="campus_destino">
                                    <option value="Campus Cancún">CANC&Uacute;N</option>
                                    <option value="Campus Colonia Del Valle CDMX">COLONIA DEL VALLE CDMX</option>
                                    <option value="Campus Cuernavaca">CUERNAVACA</option>
                                    <option value="Campus Guadalajara">GUADALAJARA</option>
                                    <option value="Campus Híbrido / Virtual">H&Iacute;BRIDO / VIRTUAL</option>
                                    <option value="...">...</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label>Matr&iacute;cula del alumno</label>
                        <div class="control">
                            <input type="text" class="input" v-model="matricula">
                        </div>
                    </div>

                    <div class="field">
                        <label>Nombre del alumno</label>
                        <div class="control">
                            <input type="text" class="input" v-model="alumno">
                        </div>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'TicketForm',
        data() {
            return {
                campus: '',
                colaborador: '',
                email: '',
                solicitud: '',
                descripcion: '',
                campus_origen: '',
                campus_destino: '',
                matricula: '',
                alumno: ''
            }
        },
        methods: {},
        mounted() {
            document.title = "Nuevo Ticket | CSH"
        }
    });
</script>