<template>
    <div class="container mt-5">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ lead.nombre }}</h1>
                <h1 class="subtitle">{{ lead.apellidos }}</h1>

                <div class="buttons">
                    <router-link :to="{ name: 'WebForm' }" class="button is-light">
                        <span class="icon">
                            <font-awesome-icon icon="arrow-alt-circle-left" />
                        </span>
                        &nbsp;&nbsp;&nbsp;Regresar
                    </router-link>
                    <router-link :to="{ name: 'EditLead', params: { id: lead.id_aspirante } }" class="button is-light">
                        <span class="icon">
                            <font-awesome-icon icon="user-edit" />
                        </span>
                        &nbsp;&nbsp;&nbsp;Editar
                    </router-link>
                    
                    <!-- <button @click="convertToClient" class="button is-info">Convert to client</button> -->
                    <!-- <button @click="deleteLead" class="button is-danger">Delete</button> -->
                </div>
            </div>

            <div class="column is-6">
                <div class="box" style="height: 250px;">
                    <h2 class="subtitle has-text-weight-bold has-text-centered">DETALLES</h2>

                    <p><strong>FECHA: </strong>{{ moment(lead.fecha_mensaje).format('DD/MM/YYYY') }}</p>
                    <p><strong>CAMPUS: </strong>{{ lead.campus }}</p>
                    <p><strong>MODELO EDUCATIVO: </strong>{{ lead.modelo }}</p>
                    <p><strong>OFERTA EDUCATIVA: </strong>{{ lead.oferta }}</p>
                    <p><strong>CARRERA: </strong>{{ lead.carrera }}</p>
                </div>
            </div>

            <div class="column is-6" >
                <div class="box" style="height: 250px;">
                    <h2 class="subtitle has-text-weight-bold has-text-centered">INFORMACI&Oacute;N DE CONTACTO</h2>

                    <p><strong>EMAIL: </strong>{{ lead.email }}</p>
                    <p><strong>TEL&Eacute;FONO: </strong>{{ lead.telefono }}</p>
                </div>
            </div>

            <div class="column is-12">
                <div class="box">
                    <h2 class="subtitle has-text-weight-bold">MENSAJE</h2>

                    <p>{{ lead.mensaje }}</p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import axios from 'axios'
    import moment from 'moment'

    export default defineComponent({
        name: 'Lead',
        created: function () {
            this.moment = moment
        },
        data() {
            return {
                lead: {}
            }
        },
        mounted() {
            document.title = "Detalle Lead Web | CSH"
            this.getLead()
        },
        methods: {
            async getLead() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id_aspirante

                await axios
                    .get(`/api/v1/leads/${leadID}/`)
                    .then(response => {
                        this.lead = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
        },
        components: {},
    });
</script>
