<template>
    <section class="hero is-warning is-fullheight-with-navbar bg-img">
        <div class="hero-body">
            <div class="column is-4 is-offset-4 has-text-black">
                <div class="has-text-centered">
                    <figure class="image is-inline-block">
                        <img src="../assets/escudoH.png" alt="Escudo Humanitas">
                    </figure>
                </div>

                <h1 class="is-size-4 has-text-black has-text-centered mt-6">ACCESO A USUARIOS</h1>
            
                <!-- <form @submit.prevent="submitForm" action="https://www.mi-escuelamx.com/Humanitas/ValidaUsuarioHumanitas.asp" method="POST" class="border-form mt-5"> -->
                <form @submit.prevent="submitForm" class="border-form mt-5">
                    <div class="field">
                        <label>USUARIO</label>
                        <div class="control">
                            <input type="text" name="email" class="input" v-model="username">
                        </div>
                    </div>

                    <div class="field mt-6">
                        <label>CONTRASE&Ntilde;A</label>
                        <div class="control">
                            <input type="password" name="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="notification is-danger mt-6" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field mt-6">
                        <div class="control">
                            <!-- <button class="button is-primary column is-12 is-size-5" type="submit">Ingresar</button> -->
                            <button class="button is-primary column is-12 is-size-5">Ingresar</button>
                        </div>
                    </div>

                    <div class="field has-text-centered mt-4 mt-4">
                        <div class="control">
                            <a class="has-text-warning is-size-7" href="https://www.mi-escuelamx.com/HUMANITAS/recuperacion_contrasena_humanitas.asp"><strong>¿Olvidaste tu Contraseña?</strong></a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>


<style scoped>
    .bg-img {
        background-image: url('../assets/brick-bg.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-color: white;
    }

    .border-form {
        padding: 40px 20px;
        border: 1px solid #c9c9c9;
    }

</style>


<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'HumanitasLogIn',
        data() {
            return {
                username: '',
                password: '',
                errors: []
            }
        },
        methods: {},
        components: {},
        mounted() {
            document.title = "Acceso a Usuarios Humanitas | CSH"
        }
    });
</script>