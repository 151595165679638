<template>
    <nav class="navbar is-primary">
        <div class="navbar-brand">
            <router-link to="/" class="navbar-item">
                <figure class="image is-32x32">
                    <img src="../../assets/favicon.png" alt="">
                </figure>
                <h1 class="is-size-3">&nbsp;CSH</h1>
            </router-link>
        </div>

        <div class="navbar-menu">
            <div class="navbar-end">
                    
                <router-link to="/humanitas-log-in" class="navbar-item">Acceso Humanitas</router-link>
                <router-link to="/ticket-formulario" class="navbar-item">Nuevo Ticket</router-link>
                
                <template v-if="$store.state.isAuthenticated">
                    <router-link to="/dashboard/leads/webform" class="navbar-item">Leads Web</router-link>
                    <router-link to="/dashboard/leads/cliengo" class="navbar-item">Leads Cliengo</router-link>
                    <router-link to="/dashboard/tickets" class="navbar-item">Tickets</router-link>
                </template>
    
                <div class="navbar-item">
                    <div class="buttons">
                        <template v-if="!$store.state.isAuthenticated">
                            <router-link to="/sign-up" class="button is-success"><strong>Sign up</strong></router-link>
                            <router-link to="/log-in" class="button is-grey-lightest">Log in</router-link>
                        </template>

                        <template v-else>
                            <router-link to="/dashboard/my-account" class="button is-info">My account</router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>


<script>
    import { defineComponent } from "vue"

    export default defineComponent({
        name: 'Navbar'
    });
</script>
