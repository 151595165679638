<template>
    <section class="hero is-white is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="is-size-1">Leads desde Cliengo</h1>
            </div>
        </div>
    </section>
</template>


<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Cliengo',
        components: {},
        mounted() {
            document.title = "Leads Cliengo | CSH"
        }
    });
</script>
