<template>
    <div class="container section">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Sign up</h1>
            
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Email</label>
                        <div class="control">
                            <input type="text" name="email" class="input" v-model="username" maxlength="8">
                        </div>
                    </div>

                    <div class="field">
                        <label>Password</label>
                        <div class="control">
                            <input type="password" name="password1" class="input" v-model="password1">
                        </div>
                    </div>

                    <div class="field">
                        <label>Repeat password</label>
                        <div class="control">
                            <input type="password" name="password2" class="input" v-model="password2">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Crear cuenta</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import axios from 'axios'
    import {toast} from 'bulma-toast'

    export default defineComponent({
        name: 'SignUp',
        data() {
            return {
                username: '',
                password1: '',
                password2: '',
                errors: []
            }
        },
        methods: {
            async submitForm() {
                this.errors = []

                if (this.username === '') {
                    this.errors.push('Escriba el usuario')
                }

                if (this.password1 === '') {
                    this.errors.push('La contraseña es muy corta')
                }

                if (this.password1 !== this.password2) {
                    this.errors.push('Las contrseñas no coinciden')
                }

                if (!this.errors.length) {
                    this.$store.commit('setIsLoading', true)

                    const formData = {
                        username: this.username,
                        password: this.password1
                    }

                    await axios
                        .post('/api/v1/users/', formData)
                        .then(response => {
                            toast({
                                message: 'Cuenta creada!, Por favor accede.',
                                type: 'is-success',
                                dismissible: true,
                                pauseOnHover: true,
                                duration: 2000,
                                position: 'bottom-right',
                            })

                            this.$router.push('/log-in')
                        })
                        .catch(error => {
                            if (error.response) {
                                for (const property in error.response.data) {
                                    this.errors.push(`${property}: ${error.response.data[property]}`)
                                }
                            } else if (error.message) {
                                this.errors.push('Algo salió mal. Inténtalo de nuevo!')
                            }
                        })
                    
                    this.$store.commit('setIsLoading', false)
                }
            }
        },
        components: {},
        mounted() {
            document.title = "Sign Up | CSH"
        },
    });
</script>