<template>
    <div class="container mt-5">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Aactualizar registro:</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field">
                        <div class="control">
                            <router-link :to="{ name: 'Lead', params: { id: lead.id_aspirante } }" class="button is-light">
                                <span class="icon">
                                    <font-awesome-icon icon="close" />
                                </span>
                                &nbsp;&nbsp;&nbsp;Cancelar
                            </router-link>

                            <button class="button is-success ml-2">
                                <span class="icon">
                                    <font-awesome-icon icon="save" />
                                </span>
                                &nbsp;&nbsp;Actualizar
                            </button>
                        </div>
                    </div>

                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>NOMBRE:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="lead.nombre">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>APELLIDOS:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="lead.apellidos">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>EMAIL:</label>
                                <div class="control">
                                    <input type="email" class="input" v-model="lead.email">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>TEL&Eacute;FONO:</label>
                                <div class="control">
                                    <input type="text" class="input" maxlength="10" v-model="lead.telefono">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>MENSAJE:</label>
                        <div class="control">
                            <textarea class="textarea" v-model="lead.mensaje" placeholder="Escribe los detalles de tu solicitud" rows="10"></textarea>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label>CAMPUS:</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.campus">
                        </div>
                    </div>
                    
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>MODELO EDUCATIVO:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="lead.modelo">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>OFERTA EDUCATIVA:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="lead.oferta">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label>CARRERA:</label>
                        <div class="control">
                            <input type="text" class="input" v-model="lead.carrera">
                        </div>
                    </div>
                    
                    <div class="field">
                        <div class="control">
                            <router-link :to="{ name: 'Lead', params: { id: lead.id_aspirante } }" class="button is-light">
                                <span class="icon">
                                    <font-awesome-icon icon="close" />
                                </span>
                                &nbsp;&nbsp;&nbsp;Cancelar
                            </router-link>

                            <button class="button is-success ml-2">
                                <span class="icon">
                                    <font-awesome-icon icon="save" />
                                </span>
                                &nbsp;&nbsp;Actualizar
                            </button>
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import axios from 'axios'
    import { toast } from 'bulma-toast'

    export default defineComponent({
        name: 'EditLead',
        data() {
            return {
                lead: {},
            }
        },
        mounted() {
            document.title = "Editar Lead Web | CSH"
            this.getLead()
        },
        methods: {
            async getLead() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id_aspirante

                await axios
                    .get(`/api/v1/leads/${leadID}/`)
                    .then(response => {
                        this.lead = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
            async submitForm() {
                this.$store.commit('setIsLoading', true)

                const leadID = this.$route.params.id_aspirante

                axios
                    .patch(`/api/v1/leads/${leadID}/`, this.lead)
                    .then(response => {
                        toast({
                            message: 'Registro actualizado!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                        this.$router.push(`/dashboard/leads/${leadID}`)
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            },
        },
        components: {},
    });
</script>
