<template>
    <section class="hero is-white is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="is-size-1">Mi perfil</h1>
                <!-- <div class="column is-12"> -->
                    <!-- <div class="buttons"> -->
                        <!-- <router-link :to="{ name: 'EditMember', params: { id: $store.state.user.id }}" class="button is-light">Edit</router-link> -->

                        <button @click="logout()" class="button is-danger">Log out</button>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </section>
</template>


<script>
    import axios from 'axios'
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'MyAccount',
        methods: {
            async logout() {
                await axios
                    .post('/api/v1/token/logout/')
                    .then(response => {
                        console.log('Logged out')
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
                
                axios.defaults.headers.common['Authorization'] = ''
                localStorage.removeItem('token')
                // localStorage.removeItem('username')
                // localStorage.removeItem('userid')
                // localStorage.removeItem('team_name')
                // localStorage.removeItem('team_id')
                this.$store.commit('removeToken')

                this.$router.push('/')
            }
        },
        components: {},
        mounted() {
            document.title = "Mi perfil | CSH"
        }
    });
</script>
