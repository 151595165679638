<template>
    <section class="hero is-white is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="is-size-1">Centro de Soporte Humanitas</h1>
            </div>
        </div>
    </section>
</template>


<script>
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Index',
        components: {},
        mounted() {
            document.title = "Inicio | CSH"
        }
    });
</script>
