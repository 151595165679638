import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import Index from '../views/Index.vue'
import TicketForm from '../views/TicketForm.vue'
import WebForm from '../views/dashboard/leads/WebForm.vue'
import AddLead from '../views/dashboard/leads/AddLead.vue'
import EditLead from '../views/dashboard/leads/EditLead.vue'
import Lead from '../views/dashboard/leads/Lead.vue'
import Cliengo from '../views/dashboard/leads/Cliengo.vue'
import Tickets from '../views/dashboard/tickets/Tickets.vue'
import MyAccount from '../views/dashboard/MyAccount.vue'
import LogIn from '../views/LogIn.vue'
import HumanitasLogIn from '../views/HumanitasLogIn'
import SignUp from '../views/SignUp.vue'
import Error404 from '../views/Error404.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/ticket-formulario',
        name: 'TicketForm',
        component: TicketForm
    },
    {
        path: '/dashboard/leads/webform',
        name: 'WebForm',
        component: WebForm,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/leads/add',
        name: 'AddLead',
        component: AddLead,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/leads/:id_aspirante',
        name: 'Lead',
        component: Lead,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/leads/:id_aspirante/edit',
        name: 'EditLead',
        component: EditLead,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/leads/cliengo',
        name: 'Cliengo',
        component: Cliengo,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/tickets',
        name: 'Tickets',
        component: Tickets,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/dashboard/my-account',
        name: 'MyAccount',
        component: MyAccount,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/humanitas-log-in',
        name: 'HumanitasLogIn',
        component: HumanitasLogIn
    },
    {
        path: '/log-in',
        name: 'LogIn',
        component: LogIn
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: SignUp
    },
    {
        path: '/:catchAll(.*)*',
        name: 'Error404',
        component: Error404,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
        next('/log-in')
    } else {
        next()
    }
})

export default router
