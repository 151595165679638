<template>
    <div class="container mt-5">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title has-text-weight-bold">NUEVO REGISTRO</h1>
            </div>

            <div class="column is-12">
                <form @submit.prevent="submitForm">
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>NOMBRE:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="nombre">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>APELLIDOS:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="apellidos">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>EMAIL:</label>
                                <div class="control">
                                    <input type="email" class="input" v-model="email">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>TEL&Eacute;FONO:</label>
                                <div class="control">
                                    <input type="text" class="input" maxlength="10" v-model="telefono">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <label>MENSAJE:</label>
                        <div class="control">
                            <textarea class="textarea" v-model="mensaje" placeholder="Escribe los detalles de tu solicitud" rows="10"></textarea>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label>CAMPUS:</label>
                        <div class="control">
                            <input type="text" class="input" v-model="campus">
                        </div>
                    </div>
                    
                    <div class="field is-horizontal">
                        <div class="field-body">
                            <div class="field">
                                <label>MODELO EDUCATIVO:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="modelo">
                                </div>
                            </div>
                            
                            <div class="field">
                                <label>OFERTA EDUCATIVA:</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="oferta">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="field">
                        <label>CARRERA:</label>
                        <div class="control">
                            <input type="text" class="input" v-model="carrera">
                        </div>
                    </div>
                    
                    <div class="field">
                        <div class="control">
                            <button class="button is-success">
                                <span class="icon">
                                    <font-awesome-icon icon="save" />
                                </span>
                                &nbsp;&nbsp;Guardar
                            </button>
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import axios from 'axios'
    import { toast } from 'bulma-toast'

    export default defineComponent({
        name: 'AddLead',
        data() {
            return {
                nombre: '',
                apellidos: '',
                email: '',
                telefono: '',
                mensaje: '',
                campus: '',
                modelo: '',
                oferta: '',
                carrera: ''
            }
        },
        mounted() {
            document.title = "Agregar Lead Web | CSH"
        },
        methods: {
            async submitForm() {
                this.$store.commit('setIsLoading', true)

                const lead = {
                    nombre: this.nombre,
                    apellidos: this.apellidos,
                    email: this.email,
                    telefono: this.telefono,
                    mensaje: this.mensaje,
                    campus: this.campus,
                    modelo: this.modelo,
                    oferta: this.oferta,
                    carrera: this.carrera
                }

                await axios
                    .post('/api/v1/leads/', lead)
                    .then(response => {
                        toast({
                            message: 'Lead guardado!',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        })

                        this.$router.push('/dashboard/leads/webform')
                    })
                    .catch(error => {
                        console.log(error)
                    })

                this.$store.commit('setIsLoading', false)
            }
        },
        components: {}
    });
</script>
