<template>
    <section class="hero is-warning is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="is-size-1">404</h1>
                <p class="is-size-4">El contenido que buscas,</p>
                <p class="is-size-4">por el momento no está disponible.</p>
                <p class="is-size-4">&nbsp;</p>
                <router-link to="/" class="button is-grey-lightest">Ir al inicio</router-link>
            </div>
        </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent ({
  name: 'Error404',
  mounted() {
    document.title = 'Error404 | CSH'
  }
});
</script>
